import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import * as styles from './team-inner.module.scss'
import { tr } from 'js/translations.js'

import Banner from 'components/Banner'
import BgLink from 'components/BgLink'
import Breadcrumb from 'components/Breadcrumb'
import IntroText from 'components/IntroText'
import Inview from 'components/Inview'
import Layout from 'components/Layout'
import Seo from 'components/Seo'
import TeamMember from 'components/TeamMember'

const Page = ({ data }) => {
  const locale = process.env.GATSBY_LOCALE
  let member = data['member']
  const page = data['page'].nodes[0]

  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
      link: '/team/',
    },
    {
      text: member.name,
    },
  ]
  const bio = renderRichText(member.bio)
  const introText = renderRichText(page.introText)
  const seoTitle = `${page.title} - ${member.name}`
  const contentJSON = JSON.parse(member.bio?.raw)
  const firstBlock = contentJSON?.content?.filter(
    (row) => row.nodeType === 'paragraph'
  )[0]
  const seoDescription = firstBlock?.content[0]?.value.replaceAll('\n', '')

  member.seo = {
    title: seoTitle,
    description: {
      description: seoDescription,
    },
  }

  return (
    <Layout>
      <div>
        <Seo page={member} locale={locale} />
        <Banner image={page.banner} locale={locale} />
        <Breadcrumb items={breadcrumb} />

        <div className='wrapper pad-sides'>
          <div className='inner'>
            <IntroText content={introText} />
            <div className={styles.member}>
              <div className={styles.image}>
                <TeamMember item={member} isPost={true} locale={locale} />
              </div>
              <div className={styles.text}>
                <Inview>
                  <div className='fade-in stagger-500'>
                    <h2>{member.name}</h2>
                    <h4>{member.jobTitle}</h4>
                    <div className={styles.line}></div>
                    <div className={styles.bio}>{bio}</div>
                  </div>
                </Inview>
                <BgLink to='/team/' text={`« ${tr('BACK_TO_ALL', locale)}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query TeamMemberBySlug($slug: String!) {
    member: contentfulTeamMember(slug: { eq: $slug }) {
      name
      jobTitle
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 0.83333333333
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
      }
      bio {
        raw
      }
    }
    page: allContentfulTeamPage {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        introText {
          raw
        }
        teamMembers {
          image {
            file {
              url
            }
          }
          name
          jobTitle
          slug
        }
      }
    }
  }
`
