import React from 'react'
import * as styles from './styles.module.scss'
import { Link } from 'gatsby'
import { tr } from 'js/translations.js'

import BgLink from 'components/BgLink'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'

const TeamMember = ({ item, hasLink, hasText, isPost, isHome, locale }) => {
  return (
    <Inview
      className={`${styles.teamMember}
      ${styles[locale]}
      ${isHome ? styles.isHome : ''}
      ${isPost ? styles.isPost : ''}
    `}
    >
      <div className={`${styles.image} fade-in`}>
        {item.image && isPost && <ImageWrap image={item.image} />}
        {item.image && !isPost && (
          <Link to={`/team/${item.slug}/`}>
            <ImageWrap image={item.image} />
          </Link>
        )}
      </div>
      {hasText && (
        <div className={`${styles.text} fade-in-up stagger-300`}>
          <h2>{item.name}</h2>
          <h4>{item.jobTitle}</h4>
          {hasLink && (
            <BgLink
              to={`/team/${item.slug}/`}
              text={`${tr('BIO', locale)} »`}
            />
          )}
        </div>
      )}
    </Inview>
  )
}

export default TeamMember
